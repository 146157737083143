.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover{
  border-color: rgba(0, 0, 0, 0.1);
  border-width: 4px;
}
.image-gallery-thumbnail:hover{
  border-color: rgba(0, 0, 0, 1);
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
  stroke-linejoin: initial;
  stroke-linecap: initial;
  color: black;
  height: 60px;
  opacity: 0.3;
  transition-duration: 300ms;
}
.image-gallery-icon:hover .image-gallery-svg{
  opacity: 1;
}
.image-gallery-icon{
  filter: none;
}