.itemCard{
  &__skeleton{
    transition-duration: 300ms;
    &.hide{
      opacity: 0;
    }
  }
  &__card{
    transition-duration: 300ms;
    opacity: 0;
    &.show{
      opacity: 1;
    }
  }
}