.CheckboxRoot {
  background-color: white;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(128, 128, 128, 0.18);
  cursor: pointer;

}
.CheckboxRoot:hover {
  background-color: rgba(128, 128, 128, 0.05);
  border-color: black;
}
.CheckboxRoot[data-state='checked']{
  background-color: black;
}
.CheckboxIndicator {
  color: white;
}
.CheckboxIndicator svg{
  height: 20px;
  width: 20px;
  margin-bottom: -3px;
}

.Label {
  color: black;
  padding-left: 15px;
  font-size: 15px;
  line-height: 1;
  cursor: pointer;
}

.AccordionRoot {
  border-radius: 6px;
  width: 300px;
  background-color: white;
  
  @media (max-width: 1280px) {
    width: 200px;
  }
}

.AccordionItem {
  overflow: hidden;
  margin-top: 1px;
}

.AccordionItem:first-child {
  margin-top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.AccordionItem:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.AccordionItem:focus-within {

}

.AccordionHeader {
  display: flex;
}

.AccordionTrigger {
  font-family: inherit;
  background-color: transparent;
  padding: 0 20px 0 0;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 1;
  color: black;
  background-color: white;
  border-bottom: 1px solid black;
}
button,
h3 {
  all: unset;
}


.AccordionContent {
  overflow: hidden;
  font-size: 15px;
  color: black;
}
.AccordionContent[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContentText {
  padding: 15px 20px;
}

.AccordionChevron {
  color: black;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionChevron > svg{
  width: 10px;
}
.AccordionTrigger[data-state='open'] > .AccordionChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}