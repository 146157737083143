.pagination{
  display: flex;
  list-style-type: none;
  gap: 16px;
  margin-top: 32px;
  padding-inline-start: 0;

  &__activePage{
    border-bottom: 1px solid black;
  }

  &__page{
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
  }
  &__next{
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
  }
  &__prev{
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
  }
}