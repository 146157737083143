.footer {
  &__link{
    color: black;
    cursor: pointer;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }
}